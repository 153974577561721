import { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Upload, message } from "antd";
import storage from "@/lib/storage";
import { UploadDocumentModal } from "./styles";
import {
  createDealerDocuments,
  createUploadAdditionalDocuments,
  getDealerConfigData,
} from "@/hooks/use-loan";
import { useQueryClient } from "@tanstack/react-query";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { Loader } from "@/pages/auth/styles/loader";

interface IUploadDocs {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  id: string;
  type?: string;
  vin: string;
  dealerId?: string;
}

export default function AdditionalDealerDocModal({
  isModalOpen,
  handleOk,
  handleCancel,
  id,
  type,
  vin,
  dealerId,
}: Readonly<IUploadDocs>) {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const queryClient = useQueryClient();
  const API_URL = process.env.REACT_APP_AXIOS_BASE_URL;
  const token = storage.getToken();
  const [loading, setLoading] = useState(false);
  const [dealerFinanceDocs, setDealerFinanceDocs] = useState<any>([]);
  const [docName, setDocName] = useState("");
  const [newDocName, setNewDocName] = useState("");
  const { t } = useTranslation("loan-form");

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  useEffect(() => {
    const getDealerFinanceProduct = async (loanId: any) => {
      try {
        const result = await getDealerConfigData(loanId, true);
        const parsedData = JSON.parse(result.data);
        const documentFields = parsedData?.documents?.filter(
          (item: { label: string }) => item.label.startsWith("Upload Documents")
        );
        setDealerFinanceDocs(documentFields[0]?.fields);
      } catch (error) {}
    };
    id && getDealerFinanceProduct(id);
  }, [id]);

  const listedDocs = dealerFinanceDocs?.filter(
    (item: Record<string, any>) =>
      item.visibility === true && item.type === "file"
  );

  const updatedListedDocs = [...listedDocs, { label: "Other", name: "other" }];

  const props = {
    name: "file",
    multiple: false,
    accept: ".png, .jpg, .pdf",
    action: `${API_URL}document/upload`,
    headers: {
      authorization: `bearer ${token}`,
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e: any) {},
  };

  const handleListedDocuments = (e: any, newValue: any) => {
    setDocName(newValue?.value);
  };

  const handleFilterDocuments = (docs: any) => {
    const filterCondition =
      type === "CAR_DOCUMENTS"
        ? (item: Record<string, any>) =>
            item?.group === "proofOfVehicleOwnership"
        : (item: Record<string, any>) =>
            item?.group !== "proofOfVehicleOwnership";

    return docs.filter(filterCondition);
  };

  const handleSubmitDealerForm = async (values: any) => {
    setLoading(true);
    const documents = [
      {
        name: docName === "other" ? newDocName : docName,
        file_url: values.document_upload[0].response.file.url,
        description: values.document_description,
        code: docName,
        password: values.password ? values.password : "",
      },
    ];

    const requestPayload =
      type === "CAR_DOCUMENTS"
        ? createDealerDocuments({
            loan_id: id,
            dealer_id: dealerId as string,
            documents,
            vin,
          })
        : createUploadAdditionalDocuments({
            loan_id: id,
            documents,
          });

    try {
      const response = await requestPayload;

      if (response) {
        message.success("Document uploaded successfully");
        queryClient.invalidateQueries();
        setLoading(false);
        setDocName("");
        form.resetFields();
        handleCancel();
      }
    } catch (error: any) {
      message.error(JSON.parse(error.response?.data).error);
      setLoading(false);
    }
  };

  const handleResetSubmitForm = () => {
    form.resetFields();
    handleCancel();
  };

  return (
    <Modal
      onOk={handleOk}
      title={
        type === "CAR_DOCUMENTS"
          ? "Upload Car document"
          : t("Upload Additional Document")
      }
      open={isModalOpen}
      footer={null}
      onCancel={handleCancel}
      closable={false}
      wrapClassName="upload-document-modal-container"
      zIndex={1007}
      width={400}
    >
      <UploadDocumentModal>
        <FormControl fullWidth style={{ marginBottom: 24 }}>
          <label className="label">{t("document-name")}</label>
          <Autocomplete
            fullWidth
            id="document_name"
            disablePortal
            options={handleFilterDocuments(updatedListedDocs)?.map(
              (list: any) => {
                return {
                  label: list?.label,
                  value: list?.name,
                };
              }
            )}
            onChange={handleListedDocuments}
            renderInput={(params) => <TextField {...params} />}
          />
          {docName === "other" && (
            <div className="mt-4">
              <label className="label">{t("enter-document-name")}</label>
              <TextField
                fullWidth
                id="document_name"
                style={{ marginTop: 8 }}
                onChange={(e: any) => setNewDocName(e.target.value)}
              />
            </div>
          )}
        </FormControl>

        <Form
          name="dealer-upload-form"
          form={form}
          layout="vertical"
          requiredMark={false}
          onFinish={handleSubmitDealerForm}
          autoComplete="off"
        >
          <Form.Item label={t("document-upload")}>
            <Form.Item
              name="document_upload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              noStyle
              rules={[
                {
                  required: true,
                  message: t("please-upload-a-file"),
                },
              ]}
            >
              <Upload.Dragger {...props} name="file" maxCount={1}>
                <div className="ant-upload-text">{t("click-or-drag")}</div>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>

          <Form.Item
            label={
              <div>
                {t("Password")}{" "}
                <span style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                  ({t("optional")})
                </span>
              </div>
            }
            name="password"
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label={
              <div>
                {t("document-description")}{" "}
                <span style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                  ({t("optional")})
                </span>
              </div>
            }
            name="document_description"
          >
            <TextArea rows={3} />
          </Form.Item>

          <Form.Item>
            <div className="button-container">
              <Button
                className="cancel-button"
                type="primary"
                onClick={handleResetSubmitForm}
              >
                {t("Cancel")}
              </Button>
              <Button
                className="submit-button"
                type="primary"
                htmlType="submit"
              >
                {loading ? <Loader variant={"secondary"} /> : t("Submit")}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </UploadDocumentModal>
    </Modal>
  );
}
