import { useRef, useState } from "react";
import { ArrowLeft, ArrowRight, ArrowTopRight } from "@/assets/svgs";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HowItWorks } from "@/data/how-auction-works";
import AuctionModal from "@/components/auctions/AuctionModal";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Galleries = [
  "https://ik.imagekit.io/eo0k4ohmt/dealer-plus/BGs/gallery-1.webp?updatedAt=1732577527492",
  "https://ik.imagekit.io/eo0k4ohmt/dealer-plus/BGs/gallery-2.webp?updatedAt=1732577541953",
  "https://ik.imagekit.io/eo0k4ohmt/dealer-plus/BGs/gallery-3.webp?updatedAt=1732577541958",
  "https://ik.imagekit.io/eo0k4ohmt/dealer-plus/BGs/gallery-4.webp?updatedAt=1732577541861",
  "https://ik.imagekit.io/eo0k4ohmt/dealer-plus/BGs/gallery-5.webp?updatedAt=1732577541762",
];

const Auctions = () => {
  const sliderRef = useRef<Slider>(null);
  const [openAuctionModal, setOpenAuctionModal] = useState(false);

  const nextButton = () => {
    sliderRef.current?.slickNext();
  };

  const previousButton = () => {
    sliderRef.current?.slickPrev();
  };

  return (
    <main className="flex flex-col gap-8 md:gap-9">
      <section className="flex flex-col gap-8 md:gap-6 px-4 py-6 md:py-7 md:px-6 bg-cover bg-no-repeat bg-[url('https://ik.imagekit.io/eo0k4ohmt/dealer-plus/BGs/Tabpanel.webp?updatedAt=1732576197485')]">
        <div className="flex flex-col xl:flex-row justify-between gap-4 md:pl-2">
          <div>
            <h1 className="text-2xl md:text-3xl text-white font-semibold leading-relaxed md:leading-snug">
              Exciting Deals!
            </h1>
            <p className="text-sm text-white font-normal">
              Discover great offers from other dealers, bank-repossessed assets,
              and quick customer sales!
            </p>
          </div>
          <button
            onClick={() => setOpenAuctionModal(true)}
            className="flex items-center gap-2 text-sm font-semibold text-[#30345E] bg-[#FFB619] px-6 rounded-full w-fit h-12"
          >
            Visit Auctions Platform <ArrowTopRight />
          </button>
        </div>
        <div className="flex flex-col gap-2">
          <div className="slider-container">
            <Slider ref={sliderRef} {...settings}>
              {Galleries.map((gallery) => (
                <div key={gallery} className="md:px-2">
                  <img
                    src={gallery}
                    alt="Gallery"
                    className="w-full h-[154px] object-cover"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="flex justify-between md:px-2">
            <p className="text-sm text-white font-semibold uppercase">
              Featured cars
            </p>
            <div className="flex items-center gap-4">
              <button
                onClick={previousButton}
                className="w-fit h-6 bg-white px-3 rounded-full"
              >
                <ArrowLeft />
              </button>
              <button
                onClick={nextButton}
                className="w-fit h-6 bg-white px-3 rounded-full"
              >
                <ArrowRight />
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="flex flex-col gap-4">
        <h2 className="text-xl text-[#30345E] font-semibold">How it works:</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-5 gap-3">
          {HowItWorks.map((elem) => (
            <div
              key={elem.title}
              className="flex flex-col gap-3 border border-solid border-[#E5E7EB] bg-white px-4 py-6 rounded-lg md:min-h-[200px] h-auto"
            >
              <div className="flex items-center justify-center gap-2 py-3 bg-[#F8FAFC] rounded-full">
                {elem.icon}
                <h2 className="text-sm text-[#30345E] font-semibold">
                  {elem.title}
                </h2>
              </div>
              <p className="text-sm text-[#6B7280] font-light">
                {elem.description}
              </p>
            </div>
          ))}
        </div>
      </section>

      <AuctionModal
        isModalOpen={openAuctionModal}
        handleOk={() => setOpenAuctionModal(false)}
        handleCancel={() => setOpenAuctionModal(false)}
      />
    </main>
  );
};

export default Auctions;
