import React, { useState } from "react";
import { CloseIcon } from "@/assets/svgs";
import { message, Modal } from "antd";
import storage from "@/lib/storage";
import axios, { AxiosError } from "axios";
import { Loader } from "@/pages/auth/styles/loader";

interface IAuctionModal {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

const AuctionModal: React.FC<IAuctionModal> = ({
  isModalOpen,
  handleOk,
  handleCancel,
}) => {
  const user = storage.getUser();
  const [loading, setLoading] = useState(false);

  const handleProceedToAuction = async () => {
    setLoading(true);
    const url = `https://voplus-kifal.kifal.ma/api/api/public/encrypt-object`;
    const headers = {
      "Content-Type": "application/json",
    };
    const data = {
      value: user?.id,
    };
    try {
      const response = await axios.post(url, data, { headers });
      window.open(
        `https://voplus-kifal.kifal.ma/login/dealer-login?id=${response.data.data}`,
        "_blank",
        "noopener"
      );
    } catch (error) {
      error instanceof AxiosError &&
        message.error(
          error?.response?.data?.message || "Error proceeding to auction"
        );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        width={470}
        wrapClassName="upload-document-modal-container"
        zIndex={1007}
      >
        <div className="p-4 md:px-6 md:pt-6 md:pb-9 flex flex-col gap-2">
          <div>
            <div className="flex justify-end">
              <button onClick={handleCancel}>
                <CloseIcon />
              </button>
            </div>
            <img
              src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/BGs/car-man-banner.webp?updatedAt=1732583156505"
              alt="Banner"
              className="w-full h-auto"
            />
          </div>

          <div className="flex flex-col gap-8">
            <div>
              <h2 className="text-xl text-[#30345E] font-semibold text-center mb-3">
                Proceed to Auction platform
              </h2>
              <p className="text-sm md:text-base font-normal text-[#30345E] text-center leading-tight mb-3">
                - Please note: Once your bid is accepted, you must complete the
                purchase. Failure to do so will result in penalties for your
                dealership.
              </p>
              <p className="text-sm md:text-base font-normal text-[#30345E] text-center leading-tight">
                - Important: All cars listed are sold "as is." Autochek will not
                be responsible for any repairs or issues with the vehicle after
                purchase.
              </p>
            </div>
            <button
              onClick={handleProceedToAuction}
              className="text-sm text-[#30345E] font-semibold w-auto h-12 bg-[#FFB619] rounded-full"
            >
              {loading ? <Loader variant="secondary" /> : "Proceed"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AuctionModal;
