import React, { useEffect } from "react";
import { LIMIT_REQUEST_STATUS } from "@/utils/finance-status";
import { useTranslation } from "react-i18next";
import RenderLoanProgress from "./FinanceCardProgress";
import formatInt from "@/utils/format-int";
import styled from "styled-components";
import { device } from "@/utils/device";
import { Progress, Tooltip } from "antd";
import { ICustomerCard } from "@/interface/dealer";
import { InfoCircleOutlined } from "@ant-design/icons";

type NotificationType = "success" | "info" | "warning" | "error";

const ProgressWrapper = styled.div`
  .ant-progress-inner {
    height: 0.375rem !important;
    @media ${device.mobileL} {
      height: 0.1875rem !important;
    }
  }
`;

const CustomerFinancingCard: React.FC<ICustomerCard> = ({
  icon,
  children,
  dealerLimit,
  dealerSummaryErr,
  dealerSummary,
  limitStatuses,
  isDealerFinancingEnabled,
  loanLimitData,
  isNonOriginatingDealer,
  api,
  resultArr,
}) => {
  const { t } = useTranslation("dealer-gamification");

  const isDeclinedStatus: any = () => {
    return dealerLimit?.loanLimits?.find(
      (item: any) => item.status === "DECLINED"
    );
  };

  useEffect(() => {
    if (
      dealerLimit?.status === LIMIT_REQUEST_STATUS.LIMIT_SIGNED_DOC_UPLOADED
    ) {
      if (
        isDeclinedStatus?.actionRequired === "REUPLOAD" ||
        isDeclinedStatus?.actionRequired === "SIGN"
      ) {
        const openNotificationWithIcon = (type: NotificationType) => {
          api[type]({
            message: "Action Required",
            description:
              isDeclinedStatus?.reasonForDecline ||
              "Your application declined, please reapply",
          });
        };
        openNotificationWithIcon("info");
      }
    }
  }, [
    api,
    dealerLimit?.status,
    isDeclinedStatus?.actionRequired,
    isDeclinedStatus?.reasonForDecline,
  ]);

  const getAvailableLimit = () => {
    const amountLeft = dealerLimit?.utility?.amountLeft ?? 0;
    const limitValue = dealerLimit?.limitValue ?? 0;

    return formatInt(
      dealerLimit?.status === LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED
        ? amountLeft
        : limitValue,
      true
    );
  };

  const renderHeaderContent = () => {
    if (isDealerFinancingEnabled) {
      if (isNonOriginatingDealer) {
        return t("dealer-financing-locked");
      } else {
        const isLimitCompleted =
          dealerLimit?.status === LIMIT_REQUEST_STATUS.LIMIT_REQUEST_COMPLETED;

        const hasActiveLimitRequest =
          dealerLimit?.status === LIMIT_REQUEST_STATUS.LIMIT_ASSIGNED ||
          limitStatuses.includes(dealerLimit?.status as LIMIT_REQUEST_STATUS);

        if (isLimitCompleted) {
          return t(`loan-limit-application-is-in-progress`);
        } else if (hasActiveLimitRequest) {
          return `${t("available-limit")}: ${getAvailableLimit()}`;
        } else {
          return t("no-assigned-loan-limit");
        }
      }
    } else {
      return t("dealer-financing-locked");
    }
  };

  let bodyContent;

  const renderBodyContent = () => {
    if (isDealerFinancingEnabled) {
      if (!isNonOriginatingDealer) {
        switch (dealerLimit?.status) {
          case LIMIT_REQUEST_STATUS.LIMIT_REQUEST_COMPLETED:
            bodyContent = t(
              "thank-you-for-applying-we-are-reviewing-your-application"
            );
            break;
          case LIMIT_REQUEST_STATUS.LIMIT_ASSIGNED:
            bodyContent = t(
              "to-start-applying-for-loans-activate-your-credit-limit"
            );
            break;
          case LIMIT_REQUEST_STATUS.LIMIT_SIGNED_DOC_UPLOADED:
            bodyContent =
              isDeclinedStatus?.actionRequired === "REUPLOAD" ||
              isDeclinedStatus?.actionRequired === "SIGN"
                ? isDeclinedStatus?.reasonForDecline ||
                  t("your-application-declined-please-reapply")
                : t("review-in-progress-we-are-activating-your-credit-limit");
            break;
          case LIMIT_REQUEST_STATUS.LIMIT_AGREEMENT_PENDING_REVIEW:
            bodyContent = t(
              "review-in-progress-we-are-activating-your-credit-limit"
            );
            break;
          case LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED:
            bodyContent = (
              <>
                {t("start-applying-for-loans")}{" "}
                <Tooltip title="Your balance will be refreshed daily">
                  <span>
                    <InfoCircleOutlined style={{ verticalAlign: "middle" }} />
                  </span>
                </Tooltip>
              </>
            );
            break;
          default:
            bodyContent = t(
              "to-enjoy-your-financing-benefits-you-need-to-set-your-loan-limit"
            );
        }
      } else {
        bodyContent = t(
          "you-are-currently-not-eligible-for-financing-sell-at-least-1-car"
        );
      }
    } else {
      bodyContent = t(`you-are-currently-not-eligible-for-financing`);
    }

    return bodyContent;
  };

  return (
    <div
      className={`${
        resultArr?.length > 1 ? "md:h-[12rem]" : "md:h-auto"
      } h-auto w-full max-w-full rounded-lg py-4 dfc-gradient`}
    >
      <div className="flex flex-col gap-4 justify-between h-full">
        <div className="flex flex-col md:flex-row items-start md:items-center">
          <div className="flex items-start md:items-center justify-between w-full px-4">
            <div className="flex items-center space-x-0 md:space-x-3">
              <div className="hidden md:flex">{icon}</div>
              <div>
                <h4
                  className={`text-xl lg:text-2xl font-bold custom-font-bold font-outfitBold text-white`}
                >
                  {renderHeaderContent()}
                </h4>
                <p className="text-sm lg:text-base font-medium text-[#EEEFFF] max-w-[17rem] md:max-w-full">
                  {renderBodyContent()}
                </p>
              </div>
            </div>
            <div className="flex md:hidden">{icon}</div>
          </div>
          {children}
        </div>

        {dealerLimit?.status === LIMIT_REQUEST_STATUS.LIMIT_ACTIVATED ? (
          <HasLimitActivated
            loanLimitData={loanLimitData}
            dealerLimit={dealerLimit}
          />
        ) : (
          <RenderLoanProgress
            dealerLimit={dealerLimit}
            dealerSummaryErr={dealerSummaryErr}
            dealerSummary={dealerSummary}
            isNonOriginatingDealer={isNonOriginatingDealer}
          />
        )}
      </div>
    </div>
  );
};

export default CustomerFinancingCard;

const HasLimitActivated = ({ loanLimitData, dealerLimit }: any) => {
  const { t } = useTranslation("dealer-gamification");

  return (
    <div className="flex flex-col w-full px-4">
      <ProgressWrapper>
        <Progress
          percent={0}
          size="small"
          trailColor="#EEEFFF"
          strokeColor="#FFB619"
          showInfo={false}
        />
      </ProgressWrapper>
      <div className="flex w-full justify-between">
        <div className="text-white font-bold">
          {formatInt(dealerLimit?.utility?.utiliseAmount ?? 0, true)}{" "}
          {t("used")}
        </div>
        <div className="text-white font-bold">
          {formatInt(dealerLimit?.utility?.loanLimitAmount ?? 0, true)}{" "}
          {t("approved")}
        </div>
      </div>
    </div>
  );
};
