import {
  CarParking,
  CarSignal,
  MoneyExchange,
  SaveMoney,
  StarSquare,
} from "@/assets/svgs";

export const HowItWorks = [
  {
    icon: <CarParking />,
    title: "Browse Cars",
    description:
      "Explore a wide selection of vehicles listed for auction. Each car comes with detailed information to help you make an informed choice.",
  },
  {
    icon: <StarSquare />,
    title: "Review and Inspect",
    description:
      "Access comprehensive vehicle reports to understand the car’s history, condition, and specifications. You can also inspect the car in person at its location.",
  },
  {
    icon: <SaveMoney />,
    title: "Place Your Bid",
    description:
      "Found the right car? Submit your bid and compete with other buyers to secure the vehicle.",
  },
  {
    icon: <MoneyExchange />,
    title: "Payment Options",
    description:
      "If your bid is accepted, you can choose to pay in cash or finance the vehicle with a pre-approved loan. (Be sure to set up your loan limit beforehand if you’re opting for financing.)",
  },
  {
    icon: <CarSignal />,
    title: "Collect Your Vehicle",
    description:
      "Once payment is confirmed, it’s time to pick up your new vehicle Enjoy your latest addition with confidence.",
  },
];
