import { SetStateAction } from "react";
import client from "@/lib/axios-client";
import storage from "@/lib/storage";
import { message } from "antd";
import { determineRole } from "./determine-role";
import { NavigateFunction } from "react-router-dom";

type TRevertUser = {
  setLoading: React.Dispatch<SetStateAction<boolean>>;
  navigate: NavigateFunction;
  onCloseMobile?: () => void;
};

const handleRevertUser = async ({
  setLoading,
  navigate,
  onCloseMobile,
}: TRevertUser) => {
  setLoading(true);
  try {
    const response: any = await client.post(`/v1/auth/revert-user`, {});
    if (response) {
      const parsedResponse = JSON.parse(response?.data);
      const token = parsedResponse?.token;
      const user = parsedResponse?.user;
      const roles = parsedResponse?.authority?.roles;
      const permissions = parsedResponse?.authority?.permissions;
      const country = parsedResponse?.user?.country;

      const role = determineRole(roles);

      if (role) storage.setRole(role);

      storage.setToken(token);
      storage.setUser(user);
      storage.setRoles(roles);
      storage.setPermissions(permissions);
      storage.setCountry(country);
      storage.clearAssumedUser();
      storage.clearProxyUserToken();

      navigate("/manage/users", { replace: true });
      setLoading(false);
      message.success("User role reverted succesfully");

      if (onCloseMobile) onCloseMobile();
    }
  } catch (error: any) {
    const errorResp = JSON.parse(error?.response?.data)?.message;
    message.error(errorResp);
    setLoading(false);
  }
};

export default handleRevertUser;
