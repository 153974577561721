import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { DeclinationReason } from "@/layouts/application-layouts/styles";
import storage from "@/lib/storage";
import DownloadAppModal from "./DownloadAppModal";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  affordabilityReasonsNG,
  ageReasonsNG,
  otherReasonsNG,
  scoreReasonsNG,
  validationReasonsNG,
  vehicleReasonsCI,
  vehicleReasonsGHAccess,
  vehicleReasonsGHGetGari,
  vehicleReasonsKEGetGari,
  vehicleReasonsKEIM,
  vehicleReasonsNGAccess,
  vehicleReasonsNGFCMB,
  vehicleReasonsNGGetGari,
  vehicleReasonsNGHallmark,
  vehicleReasonsNGStanbic,
  vehicleReasonsNGVFD,
  vehicleReasonsSNGetGari,
  vehicleReasonsUGGetGari,
} from "@/data/decline-reason";

type LoanOfferProps = {
  offers: Record<string, any>;
  loan: any;
};

type TReasons =
  | "ageReasons"
  | "scoreReasons"
  | "vehicleReasons"
  | "affordabilityReasons"
  | "validationReasons"
  | "otherReasons";

type IAvailableRouter = "NG" | "GH" | "UG" | "KE";

interface ReasonData {
  [key: string]: boolean;
}

interface SimulatedReasonData {
  [key: string]: string;
}

const allReasonIcon = {
  vehicleReason:
    "https://media.autochek.africa/file/publicAssets/Group-66-h.svg",
  creditReason:
    "https://media.autochek.africa/file/publicAssets/Frame-36666-Q.png",
  documentReason:
    "https://media.autochek.africa/file/publicAssets/Frame-36664-1-z.png",
  nonFinanceableCar:
    "https://media.autochek.africa/file/publicAssets/Frame-36664-o.png",
  declineReason:
    "https://media.autochek.africa/file/publicAssets/Frame-36664-1.svg",
  ageReason:
    "https://media.autochek.africa/file/publicAssets/Frame-36666-1.png",
};

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none",
        },
        ".expandIconWrapper": {
          display: "none",
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block",
        },
      }}
    >
      <div className="expandIconWrapper">
        <img src="/images/minus.svg" alt="" style={{ width: "70%" }} />
      </div>
      <div className="collapsIconWrapper">
        <img src="/images/plus.svg" alt="" style={{ width: "70%" }} />
      </div>
    </Box>
  );
};

const DeclinationReasonLayout = ({ offers, loan }: LoanOfferProps) => {
  const country = storage.getCountry();
  const localesWithReasons = ["NG", "KE", "UG", "GH", "CI", "SN"];
  const locale = country?.toUpperCase() ?? "NG";
  const loanLocale = localesWithReasons.includes(locale) ? locale : "NG";
  const { t } = useTranslation("smart-notification");
  const location = useLocation();
  const isSimulationPath =
    location.pathname === `/applications/offer-simulation-history`;

  const [expanded, setExpanded] = useState(0);
  const [openAppModal, setOpenAppModal] = useState(false);

  function collapsibleText(str: string, action: () => void) {
    return (
      <Typography fontSize="16px" fontWeight={400}>
        {str}
        <button
          onClick={action}
          style={{ color: "#E6A314", cursor: "pointer" }}
        >
          ... {t("see-more")}
        </button>
      </Typography>
    );
  }

  function getTrueReasons(data: ReasonData): string[] {
    const trueReasons: string[] = [];

    for (const key in data) {
      if (data.hasOwnProperty(key) && data[key] === true) {
        trueReasons.push(key);
      }
    }

    return trueReasons;
  }

  function getSimulatedReasons(data: SimulatedReasonData): string[] {
    const trueReasons: string[] = [];

    for (const key in data) {
      if (data.hasOwnProperty(key) && data[key] === "1") {
        trueReasons.push(key);
      }
    }

    return trueReasons;
  }

  const realReasons = getTrueReasons(offers?.rejectionReasons as ReasonData);
  const simulatedReasons = getSimulatedReasons(
    offers?.declineReasons as SimulatedReasonData
  );

  const trueReasons = realReasons?.length > 0 ? realReasons : simulatedReasons;

  const NGPartnersVehicleReasonsMap: Record<string, JSX.Element> = {
    getgari: vehicleReasonsNGGetGari(t),
    vfd: vehicleReasonsNGVFD(t),
    hallmarkfinance: vehicleReasonsNGHallmark(t),
    accessbankng: vehicleReasonsNGAccess(t),
    stanbicng: vehicleReasonsNGStanbic(t),
    fcmbng: vehicleReasonsNGFCMB(t),
    renmoneyng: vehicleReasonsNGGetGari(t),
  };

  const GHPartnersVehicleReasonsMap: Record<string, JSX.Element> = {
    getgari: vehicleReasonsGHGetGari(t),
    accessbankgh: vehicleReasonsGHAccess(t),
  };

  const KEPartnersVehicleReasonsMap: Record<string, JSX.Element> = {
    getgari: vehicleReasonsKEGetGari(t),
    imbankke: vehicleReasonsKEIM(t),
  };

  const UGPartnersVehicleReasonsMap: Record<string, JSX.Element> = {
    getgari: vehicleReasonsUGGetGari(t),
  };

  const CIPartnersVehicleReasonsMap: Record<string, JSX.Element> = {
    getgari: vehicleReasonsCI(t),
  };

  const SNPartnersVehicleReasonsMap: Record<string, JSX.Element> = {
    getgari: vehicleReasonsSNGetGari(t),
  };

  const NGVehicleReasonsByPartner = (code: string) => {
    return NGPartnersVehicleReasonsMap[code] || vehicleReasonsNGGetGari(t);
  };

  const GHVehicleReasonsByPartner = (code: string) => {
    return GHPartnersVehicleReasonsMap[code] || vehicleReasonsGHGetGari(t);
  };

  const KEVehicleReasonsByPartner = (code: string) => {
    return KEPartnersVehicleReasonsMap[code] || vehicleReasonsKEGetGari(t);
  };

  const UGVehicleReasonsByPartner = (code: string) => {
    return UGPartnersVehicleReasonsMap[code] || vehicleReasonsUGGetGari(t);
  };

  const CIVehicleReasonsByPartner = (code: string) => {
    return CIPartnersVehicleReasonsMap[code] || vehicleReasonsCI(t);
  };

  const SNVehicleReasonsByPartner = (code: string) => {
    return SNPartnersVehicleReasonsMap[code] || vehicleReasonsSNGetGari(t);
  };

  const rejectionReasonsBody = {
    NG: {
      vehicleReasons: NGVehicleReasonsByPartner(offers?.partner?.code),
      affordabilityReasons: affordabilityReasonsNG(t),
      scoreReasons: scoreReasonsNG(t),
      ageReasons: ageReasonsNG(t),
      validationReasons: validationReasonsNG(t),
      otherReasons: otherReasonsNG(t),
    },
    GH: {
      vehicleReasons: GHVehicleReasonsByPartner(offers?.partner?.code),
      affordabilityReasons: affordabilityReasonsNG(t),
      scoreReasons: scoreReasonsNG(t),
      ageReasons: ageReasonsNG(t),
      validationReasons: validationReasonsNG(t),
      otherReasons: otherReasonsNG(t),
    },
    UG: {
      vehicleReasons: UGVehicleReasonsByPartner(offers?.partner?.code),
      affordabilityReasons: affordabilityReasonsNG(t),
      scoreReasons: scoreReasonsNG(t),
      ageReasons: ageReasonsNG(t),
      validationReasons: validationReasonsNG(t),
      otherReasons: otherReasonsNG(t),
    },
    KE: {
      vehicleReasons: KEVehicleReasonsByPartner(offers?.partner?.code),
      affordabilityReasons: affordabilityReasonsNG(t),
      scoreReasons: scoreReasonsNG(t),
      ageReasons: ageReasonsNG(t),
      validationReasons: validationReasonsNG(t),
      otherReasons: otherReasonsNG(t),
    },
    CI: {
      vehicleReasons: CIVehicleReasonsByPartner(offers?.partner?.code),
      affordabilityReasons: affordabilityReasonsNG(t),
      scoreReasons: scoreReasonsNG(t),
      ageReasons: ageReasonsNG(t),
      validationReasons: validationReasonsNG(t),
      otherReasons: otherReasonsNG(t),
    },
    SN: {
      vehicleReasons: SNVehicleReasonsByPartner(offers?.partner?.code),
      affordabilityReasons: affordabilityReasonsNG(t),
      scoreReasons: scoreReasonsNG(t),
      ageReasons: ageReasonsNG(t),
      validationReasons: validationReasonsNG(t),
      otherReasons: otherReasonsNG(t),
    },
  };

  const redirectToApplication = () => {
    switch (loan?.productType) {
      case "Logbook Finance":
        return `/applications/create-car-for-cash?loan_id=${loan?.id}`;
      case "Dealer WhiteLabel":
        return `/applications/loan-transfer?car_id=${loan?.carId}&loan_id=${loan?.id}`;
      default:
        return `/applications/loans?car_id=${loan?.carId}&loan_id=${loan?.id}`;
    }
  };

  const toChangeCar = () => {
    const queryParams = new URLSearchParams();

    const addQueryParam = (key: string, value?: string) => {
      if (value) {
        queryParams.append(key, value);
      }
    };

    addQueryParam(
      "price_high",
      offers?.loanApplicationMaximumApprovedVehicleValue
    );
    addQueryParam("loan_id", loan?.id);

    return `/applications/car-list?${queryParams.toString()}`;
  };

  const allCountryReasons = {
    vehicleReasons: {
      header: t("vehicle-not-financeable"),
      summary: t("we-regret-to-inform"),
      icon: allReasonIcon.nonFinanceableCar,
      action: [
        {
          btnText: t("view-eligible-cars"),
          link: "/applications/select-cars",
        },
      ],
      body: {
        NG: rejectionReasonsBody.NG.vehicleReasons,
        GH: rejectionReasonsBody.GH.vehicleReasons,
        KE: rejectionReasonsBody.KE.vehicleReasons,
        UG: rejectionReasonsBody.UG.vehicleReasons,
        CI: rejectionReasonsBody.CI.vehicleReasons,
        SN: rejectionReasonsBody.SN.vehicleReasons,
      },
    },
    affordabilityReasons: {
      header: t("application-declined-due-insufficient-affordability"),
      summary: t("selected-vehicle-has-been-declined"),
      icon: allReasonIcon.declineReason,
      action: [
        {
          link: redirectToApplication(),
          btnText: t("offer-higher-equity-extend-loan-term"),
        },
        {
          link: toChangeCar(),
          btnText: t("change-car"),
        },
      ],
      body: {
        NG: rejectionReasonsBody.NG.affordabilityReasons,
        GH: rejectionReasonsBody.GH.affordabilityReasons,
        KE: rejectionReasonsBody.KE.affordabilityReasons,
        UG: rejectionReasonsBody.UG.affordabilityReasons,
        CI: rejectionReasonsBody.CI.affordabilityReasons,
        SN: rejectionReasonsBody.SN.affordabilityReasons,
      },
    },
    scoreReasons: {
      header: t("application-declined-due-to-low-credit-score"),
      icon: allReasonIcon.creditReason,
      summary: t("we-regret-to-inform-you-declined-due-to-low credit-score"),
      action: [
        {
          link: redirectToApplication(),
          btnText: t("increase-equity-contribution"),
        },
        { link: "/", btnText: t("download-app") },
      ],
      body: {
        NG: rejectionReasonsBody.NG.scoreReasons,
        GH: rejectionReasonsBody.GH.scoreReasons,
        KE: rejectionReasonsBody.KE.scoreReasons,
        UG: rejectionReasonsBody.UG.scoreReasons,
        CI: rejectionReasonsBody.CI.scoreReasons,
        SN: rejectionReasonsBody.SN.scoreReasons,
      },
    },
    ageReasons: {
      header: t("application-declined-due-to-age"),
      summary: t("we-regret-to-inform-do-not-meet-age-criteria"),
      icon: allReasonIcon.ageReason,
      action: [
        {
          link: `/applications/car-list?loan_id=${loan?.id}`,
          btnText: t("view-non-finance-cars"),
        },
      ],
      body: {
        NG: rejectionReasonsBody.NG.ageReasons,
        GH: rejectionReasonsBody.GH.ageReasons,
        KE: rejectionReasonsBody.KE.ageReasons,
        UG: rejectionReasonsBody.UG.ageReasons,
        CI: rejectionReasonsBody.CI.ageReasons,
        SN: rejectionReasonsBody.SN.ageReasons,
      },
    },

    validationReasons: {
      header: t("document-validation-declined"),
      summary: t(
        "we-regret-to-inform-you-were-not-able-to-successfully-validate"
      ),
      icon: allReasonIcon.documentReason,
      reason: t("we-regret-to-inform-chosen-vehicle-has-been-declined"),
      action: [
        {
          link: `/applications/car-list?loan_id=${loan?.id}`,
          btnText: t("view-non-finance-cars"),
        },
      ],
      body: {
        NG: rejectionReasonsBody.NG.validationReasons,
        GH: rejectionReasonsBody.GH.validationReasons,
        KE: rejectionReasonsBody.KE.validationReasons,
        UG: rejectionReasonsBody.UG.validationReasons,
        CI: rejectionReasonsBody.CI.validationReasons,
        SN: rejectionReasonsBody.SN.validationReasons,
      },
    },
    otherReasons: {
      header: t("application-declined"),
      summary: t(
        "we-regret-to-inform-you-unable-to-approve-your-application-for-a-car-loan"
      ),
      icon: allReasonIcon.declineReason,
      reason: t("we-regret-to-inform-chosen-vehicle-has-been-declined"),
      action: [{ link: "/applications", btnText: t("back-home") }],
      body: {
        NG: rejectionReasonsBody.NG.otherReasons,
        GH: rejectionReasonsBody.GH.otherReasons,
        KE: rejectionReasonsBody.KE.otherReasons,
        UG: rejectionReasonsBody.UG.otherReasons,
        CI: rejectionReasonsBody.CI.otherReasons,
        SN: rejectionReasonsBody.SN.otherReasons,
      },
    },
  };

  const getButtonStyle = (i: any) => ({
    display:
      allCountryReasons[trueReasons[0] as TReasons]?.action.length > 1
        ? "flex"
        : "block",
    width: i === 1 ? "fit-content" : "100%",
  });

  return (
    <DeclinationReason>
      <Stack>
        {trueReasons.length > 1 ? (
          trueReasons.map((reason, i) => {
            const boxIndex = i + 1;
            return (
              <Box
                sx={{
                  boxShadow: "rgba(17, 17, 26, 0.1) 0px 0px 16px",
                  borderRadius: "8px",
                  margin: "auto auto 32px !important",
                }}
                key={boxIndex}
                width={{ md: "100%", xs: "100%", xl: "100%" }}
              >
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  paddingY={{ md: 4, xs: 6 }}
                  paddingX={{ md: 10, xs: 4 }}
                  paddingBottom={0}
                >
                  <img
                    src={allCountryReasons[reason as TReasons]?.icon}
                    alt="non-finance car"
                    style={{ width: "30%", margin: "auto" }}
                  />

                  <Box sx={{ marginTop: 2 }}>
                    <Accordion
                      sx={{
                        boxShadow: "none",
                        paddingBottom: 0,
                      }}
                      expanded={expanded === boxIndex}
                      onChange={() => {
                        expanded === boxIndex
                          ? setExpanded(0)
                          : setExpanded(boxIndex);
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<CustomExpandIcon />}
                        aria-controls="panel1a-content"
                        sx={{
                          boxShadow: "none",
                        }}
                        id="panel1a-header"
                      >
                        <Typography
                          color="#30345E"
                          fontSize="18px"
                          fontWeight={600}
                        >
                          {allCountryReasons[reason as TReasons]?.header}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div>
                          {
                            allCountryReasons[reason as TReasons]?.body[
                              loanLocale as IAvailableRouter
                            ]
                          }
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    {expanded !== boxIndex && (
                      <Typography
                        sx={{ paddingX: 2 }}
                        color="#6B7280"
                        fontSize="16px"
                        fontWeight={300}
                      >
                        {collapsibleText(
                          allCountryReasons[reason as TReasons]?.summary,
                          () => setExpanded(boxIndex)
                        )}
                      </Typography>
                    )}

                    {isSimulationPath ? (
                      <></>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          gap: { md: 2, xs: 0 },
                        }}
                        flexDirection={{ md: "row", xs: "column" }}
                        alignItems={{ xs: "center" }}
                      >
                        {allCountryReasons[reason as TReasons]?.action.map(
                          (action, i) => {
                            const getButtonStyleReason = (i: any) => ({
                              display:
                                allCountryReasons[reason as TReasons]?.action
                                  .length > 1
                                  ? "flex"
                                  : "block",
                              width: i === 1 ? "fit-content" : "100%",
                            });

                            const styles = getButtonStyleReason(i);
                            return (
                              <div key={action.btnText} style={styles}>
                                {action.btnText.includes("Download") ? (
                                  <Button
                                    variant="contained"
                                    sx={{
                                      marginTop: 3,
                                      borderRadius: "50px",
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      paddingY: 1.5,
                                      color: "#30345E",
                                      background:
                                        i === 1 ? "transparent" : "secondary",
                                      border:
                                        i === 1 ? "1px solid #30345E" : "",
                                      paddingX: i === 1 ? 5 : "",
                                      whiteSpace: "nowrap",
                                    }}
                                    fullWidth
                                    type="button"
                                    color="inherit"
                                    onClick={() => setOpenAppModal(true)}
                                  >
                                    {action.btnText}
                                  </Button>
                                ) : (
                                  <a
                                    href={action.link}
                                    style={{
                                      width:
                                        allCountryReasons[reason as TReasons]
                                          .action.length > 1
                                          ? "100%"
                                          : "fit-content",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      sx={{
                                        marginTop: 3,
                                        borderRadius: "50px",
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        paddingY: 1.5,
                                        color: "#30345E",
                                        background:
                                          i === 1 ? "transparent" : "#ffb619",
                                        border:
                                          i === 1 ? "1px solid #30345E" : "",
                                        paddingX: i === 1 ? 5 : "",
                                      }}
                                      fullWidth
                                      type="button"
                                      color="inherit"
                                    >
                                      {action.btnText}
                                    </Button>
                                  </a>
                                )}
                              </div>
                            );
                          }
                        )}
                      </Box>
                    )}
                  </Box>
                </Stack>
              </Box>
            );
          })
        ) : (
          <Box
            sx={{
              boxShadow: "rgba(17, 17, 26, 0.1) 0px 0px 16px",
              borderRadius: "8px",
              background: "#FFF",
              margin: "16px auto !important",
              paddingTop: 4,
              paddingBottom: 8,
            }}
            width={{ md: "100%", xs: "100%" }}
          >
            <Stack
              justifyContent="center"
              alignItems="center"
              paddingY={{ md: 4, xs: 6 }}
              paddingX={{ md: 4, xs: 4 }}
              paddingBottom={0}
            >
              <img
                src={allCountryReasons[trueReasons[0] as TReasons]?.icon}
                alt="non-finance car"
                style={{ width: "30%", margin: "auto" }}
              />

              <Box sx={{ marginTop: 2 }}>
                <Typography
                  color="#30345E"
                  textAlign={"center"}
                  fontSize="20px"
                  fontWeight={800}
                  mb={2}
                >
                  {allCountryReasons[trueReasons[0] as TReasons]?.header}
                </Typography>

                <div>
                  {
                    allCountryReasons[trueReasons[0] as TReasons]?.body[
                      loanLocale as IAvailableRouter
                    ]
                  }
                </div>

                {isSimulationPath ? (
                  <></>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      gap: { md: 2, xs: 0 },
                    }}
                    flexDirection={{ md: "row", xs: "column" }}
                    alignItems={{ xs: "center" }}
                  >
                    {allCountryReasons[trueReasons[0] as TReasons]?.action.map(
                      (action, i) => {
                        const styles = getButtonStyle(i);
                        return (
                          <div key={action?.btnText} style={styles}>
                            {action?.btnText.includes("Download") ? (
                              <Button
                                variant="contained"
                                sx={{
                                  marginTop: 3,
                                  borderRadius: "50px",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  paddingY: 1.5,
                                  background:
                                    i === 1 ? "transparent" : "secondary",
                                  border: i === 1 ? "1px solid #30345E" : "",
                                  paddingX: i === 1 ? 5 : "",
                                  whiteSpace: "nowrap",
                                }}
                                fullWidth
                                type="button"
                                color="secondary"
                                onClick={() => setOpenAppModal(true)}
                              >
                                {action.btnText}
                              </Button>
                            ) : (
                              <a
                                href={action.link}
                                style={{
                                  width:
                                    allCountryReasons[
                                      trueReasons[0] as TReasons
                                    ].action.length > 1
                                      ? "100%"
                                      : "fit-content",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  sx={{
                                    marginTop: 3,
                                    borderRadius: "50px",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    paddingY: 1.5,
                                    background:
                                      i === 1 ? "transparent" : "secondary",
                                    border: i === 1 ? "1px solid #30345E" : "",
                                    paddingX: i === 1 ? 5 : "",
                                  }}
                                  fullWidth
                                  type="button"
                                  color="secondary"
                                >
                                  {action.btnText}
                                </Button>
                              </a>
                            )}
                          </div>
                        );
                      }
                    )}
                  </Box>
                )}
              </Box>
            </Stack>
          </Box>
        )}

        <DownloadAppModal
          open={openAppModal}
          handleClose={() => setOpenAppModal(false)}
        />
      </Stack>
    </DeclinationReason>
  );
};

export default DeclinationReasonLayout;
