export enum LOAN_STATUS {
  APPLICATION_COMPLETED = "APPLICATION_COMPLETED",
  CONDITIONAL_OFFER_RECEIVED = "CONDITIONAL_OFFER_RECEIVED",
}

export const getOfferByColor = (status: string): string => {
  switch (status) {
    case "ACCEPTED":
      return "success";
    case "OFFER_REJECTED":
      return "error";
    case "PENDING":
      return "processing";
    default:
      return "";
  }
};
