import { Drawer, List } from "antd";
import storage from "@l/storage";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import authState from "../../states/auth";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Loader } from "@/pages/auth/styles/loader";
import { useState } from "react";
import { resetCarForm } from "@/pages/applications/car-form/helper";
import {
  franchiseMenus,
  menus,
  otherRoleMenus,
  otherRoleMenusDF,
} from "@/data/menu-navs";
import handleRevertUser from "@/utils/revert-users";
import { NewMenuIcon } from "@/assets/svgs";
import { DEALER_FINANCE_COUNTRIES } from "@/utils/finance-status";
interface IMobile {
  openMobile: boolean;
  onCloseMobile: () => void;
}

const StyledMobileNav = styled.div`
  margin-top: 56px;
  .m-nav-item-container {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .m-nav-item {
    font-size: 16px;
    font-weight: 700;
    color: #30345e;
  }
`;

const MobileNav = ({ openMobile, onCloseMobile }: IMobile) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isFranchise = storage.utilities.isFranchise();
  const isAccountManager = storage.utilities.isAccountManager();
  const isDealerDSA = storage.utilities.isDealerDsa();
  const setLogin = authState((state: IAuthState) => state.setLogin);
  const { t } = useTranslation("navigation");
  const [loading, setLoading] = useState(false);
  const isAssumedUser = storage.getAssumedUser();
  const country = storage.getCountry();
  const isDealerFinancingEnabled = DEALER_FINANCE_COUNTRIES.includes(country);

  let navMenus;

  if (isFranchise) {
    navMenus = franchiseMenus(country);
  } else if (isAccountManager && isDealerFinancingEnabled) {
    navMenus = otherRoleMenusDF;
  } else if (isAccountManager || isDealerDSA) {
    navMenus = otherRoleMenus;
  } else {
    navMenus = menus;
  }

  const handleSignOut = () => {
    queryClient.clear();
    storage.clearAll();
    resetCarForm();
    setLogin(false);
    navigate("/auth/sign-in", { replace: true });
  };

  return (
    <Drawer
      placement="left"
      onClose={onCloseMobile}
      open={openMobile}
      closable={false}
      rootClassName="mobile-nav-drawers"
      maskStyle={{ padding: 0 }}
    >
      <StyledMobileNav>
        <List
          size="large"
          dataSource={navMenus}
          footer={
            <button onClick={handleSignOut} className="m-nav-item-container">
              <img
                src="https://media.autochek.africa/file/publicAssets/Vector-Stroke-1.svg"
                alt="logout-icon"
              />{" "}
              <div className="m-nav-item">{t("logout")}</div>
            </button>
          }
          renderItem={(item) => (
            <List.Item style={{ paddingLeft: "0px" }}>
              <button
                className="m-nav-item flex items-center gap-1 capitalize"
                onClick={() => {
                  navigate(`${item?.path}`, { replace: true });
                  onCloseMobile();
                }}
              >
                {t(item?.title)}
                {item.new && <NewMenuIcon />}
              </button>
            </List.Item>
          )}
        />

        {isAssumedUser && (
          <button
            className="text-sm bg-[#ffb619] text-[#30345e] font-semibold rounded-[44px] min-w-[126px] w-auto h-[32px] px-3 mt-5"
            onClick={() =>
              handleRevertUser({ setLoading, navigate, onCloseMobile })
            }
          >
            {loading ? <Loader variant="secondary" /> : "REVERT USER"}
          </button>
        )}
      </StyledMobileNav>
    </Drawer>
  );
};

export default MobileNav;
