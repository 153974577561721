export const menus = [
  {
    title: "dashboard",
    path: "/dashboard",
    new: false,
  },
  {
    title: "applications",
    path: "/applications",
    new: false,
  },
  {
    title: "loan-calculator",
    path: "/loan-calculator",
    new: false,
  },
  {
    title: "prequalification",
    path: "/prequalification",
    new: false,
  },
];

export const otherRoleMenus = [
  {
    title: "dashboard",
    path: "/dashboard",
    new: false,
  },
  {
    title: "applications",
    path: "/applications",
    new: false,
  },
  {
    title: "inventory",
    path: "/inventory",
    new: false,
  },
  {
    title: "loan-calculator",
    path: "/loan-calculator",
    new: false,
  },
  {
    title: "prequalification",
    path: "/prequalification",
    new: false,
  },
];

export const otherRoleMenusDF = [
  {
    title: "dashboard",
    path: "/dashboard",
    new: false,
  },
  {
    title: "applications",
    path: "/applications",
    new: false,
  },
  {
    title: "financing",
    path: "/financing",
    new: true,
  },
  {
    title: "inventory",
    path: "/inventory",
    new: false,
  },
  {
    title: "loan-calculator",
    path: "/loan-calculator",
    new: false,
  },
  {
    title: "prequalification",
    path: "/prequalification",
    new: false,
  },
];

export const dealerFinanceManagerMenu = [
  {
    title: "dashboard",
    path: "/dashboard",
    new: false,
  },
  {
    title: "financing",
    path: "/financing",
    new: true,
  },
  {
    title: "inventory",
    path: "/inventory",
    new: false,
  },
  {
    title: "loan-calculator",
    path: "/loan-calculator",
    new: false,
  },
  {
    title: "prequalification",
    path: "/prequalification",
    new: false,
  },
];

export const franchiseMenus = (country: string) => {
  const baseMenus = [
    {
      title: "dashboard",
      path: "/dealer/dashboard",
      new: false,
    },
    {
      title: "applications",
      path: "/dealer/applications",
      new: false,
    },
    {
      title: "my-financing",
      path: "/dealer/financing",
      new: false,
    },
    {
      title: "inventory",
      path: "/dealer/inventory",
      new: false,
    },
  ];

  if (country === "KE") {
    baseMenus.push({
      title: "auctions",
      path: "/auctions",
      new: true,
    });
  }

  return baseMenus;
};

export const adminMenus = [
  {
    title: "Manage Users",
    path: "/manage/users",
    new: false,
  },
];
